import axios from '../../axios';
import * as actionTypes from './actionTypes';

export const initGames = (locale) => {
	return dispatch => {
		axios.get('/site/v1/games', {
			headers: {
				locale,
			},
		})
			.then(res => {
				const fetchedGames = res.data.payload;

				dispatch(fetchGamesSuccess(fetchedGames));
			})
			.catch(err => {
				// dispatch(fetchGamesFail(err));
			});
	};
};

export const fetchGamesSuccess = (categories) => {
	return {
		type: actionTypes.FETCH_GAMES_SUCCESS,
		categories
	};
};

// export const fetchGamesFail = (error) => {
// 	return {
// 		type: actionTypes.FETCH_GAMES_FAIL,
// 		error: error
// 	};
// };

export const changeActiveCategory = (category) => {
	return {
		type: actionTypes.CHANGE_ACTIVE_CATEGORY,
		category
	};
};
