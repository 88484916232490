import React from 'react';

import classes from './Features.module.scss';

import joystickIcon from '../../assets/images/mainPage/joystick.svg';
import filmIcon from '../../assets/images/mainPage/film.svg';
import broadcastIcon from '../../assets/images/mainPage/broadcast.svg';

import Feature from './Feature/Feature';

const features = (props) => {
  const { t } = props;
  const featuresList = [
    {icon: joystickIcon, title: t('mainPage.features.games.title'), text: t('mainPage.features.games.description'), alt: 'joystick'},
    {icon: filmIcon, title: t('mainPage.features.film.title'), text: t('mainPage.features.film.description'), alt: 'film'},
    {icon: broadcastIcon, title: t('mainPage.features.broadcasting.title'), text: t('mainPage.features.broadcasting.description'), alt: 'broadcast'}
  ];

  return (
    <ul className={classes.Features}>
      {featuresList.map((feature, idx) => {
        return <Feature key={idx} isEven={(idx + 1) % 2 === 0} feature={feature} />;
      })}
    </ul>
  );
};

export default features;