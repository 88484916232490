import React from 'react';

import classes from './FranchizeFigure.module.scss';
import FranchizeButton from '../UI/FranchizeButton/FranchizeButton';

const franchizeFigure = props => {
  return (
    <div className={classes.FranchizeFigure}>
      <p className={classes.Description}>Подробная презентация и финансовая модель для Вашего города будут доступны после заполнения заявки</p>
      <FranchizeButton onLeaveApplication={props.onLeaveApplication} />
    </div>
  );
};

export default franchizeFigure;