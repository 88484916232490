import React, {Component} from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import classes from './NewsPage.module.scss';
import Aux from '../../hoc/Aux/Aux';
import Button from '../../components/UI/Button/Button';
import * as actions from '../../store/actions/index';
import News from '../../components/News/News';

class NewsPage extends Component {
  componentDidMount() {
    const { currentLang, pageSize, onFetchNews } = this.props;
    onFetchNews(currentLang, pageSize);
  }

  render() {
    const {news, totalNews, pageSize, currentLang, onShowMore, t} = this.props;
    
    return (
      <Aux>
        <div className={classes.Container}>
          <News news={news} path={this.props.match.path} t={t} />
          {
            totalNews > news.length ?
              <p className={classes.ShowMore}>
                <Button btnColor="Transparent" clicked={() => onShowMore(currentLang, pageSize)}>{t('newsPage.showBtn')}</Button>
              </p> : null
          }
        </div>
      </Aux>
    );
  }
};

const mapStateToProps = state => {
  return {
    news: state.newsReducer.news,
    pageSize: state.newsReducer.pageSize,
    totalNews: state.newsReducer.totalNews,
    currentLang: state.localizationReducer.currentLang,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onFetchNews: (lang, pageSize) => dispatch(actions.fetchNews(lang, pageSize)),
    onShowMore: (lang, currentNewsLength) => dispatch(actions.showMoreNews(lang, currentNewsLength)),
  }
};

const NewsPageTranslation = withTranslation()(NewsPage);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NewsPageTranslation));