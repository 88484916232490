import React from 'react';

import classes from './Feature.module.scss';

const feature = (props) => {
  let classNames = [classes.Feature];

  if (props.isEven) {
    classNames = [classes.Feature, classes.Even];
  }

  return(
    <li className={classNames.join(' ')}>
      <div className={classes.FeatureLeft}>
        <img src={props.feature.icon} alt={props.feature.alt} />
      </div>
      <div className={classes.FeatureRight}>
        <p className={classes.FeatureTitle}>{props.feature.title}</p>
        <p className={classes.FeatureText}>{props.feature.text}</p>
      </div>
    </li>
  );
};

export default feature;
