import React from 'react';

import classes from './Investments.module.scss';
import investmentImg from '../../assets/images/franchizePage/investment.png';
import FranchizeTitle from '../UI/FranchizeTitle/FranchizeTitle';
import FranchizeDescription from '../UI/FranchizeDescription/FranchizeDescription';
import FranchizeFigure from '../FranchizeFigure/FranchizeFigure';

const investments = props => {
  const items = ['Техника', 'Ремонт помещения', 'Мебель и оборудование для бара', 'Кальянное оборудование и первая закупка '];
  const features = [
    { title: '10-14 месяцев', description: 'Срок окупаемости' },
    { title: 'от $9 000', description: 'Средний оборот в месяц' },
    { title: '25-40%', description: 'Рентабильность' },
    { title: 'Роялти', description: '3% от оборота с 4го месяца после запуска Маркетинговый сбор: 1% от дохода' },
  ];

  return (
    <section className={classes.Investments}>
      <div className={classes.Container}>
        <div className={classes.Top}>
          <div className={classes.Left}>
            <FranchizeTitle><span>Стартовые инвестиции</span> на открытие одного центра</FranchizeTitle>
            <FranchizeDescription>Паушальный взнос $7  000 <br /> Затраты до открытия $30 000 – 50 000, а именно: </FranchizeDescription>
            <ul className={classes.Items}>
              {items.map((item, idx) => <li key={idx} className={classes.Item}>{item}</li>)}
            </ul>
            <p className={classes.Summary}>Итого инвестиции около <span>$57 000</span></p>
          </div>
          <div className={classes.Right}><img src={investmentImg} alt="investment" /></div>
        </div>

        <div className={classes.Center}>
          <ul className={classes.Features}>
            {features.map((feature, idx) => {
              return (
                <li key={idx} className={classes.Feature}>
                  <p className={classes.Title}>{feature.title}</p>
                  <p className={classes.Description}>{feature.description}</p>
                </li>
              );
            })}
          </ul>
        </div>

        <p className={classes.Results}>Все результаты индивидуальны и зависят от определённых обстоятельств или особенностей клиента</p>
        
        <div className={classes.Bottom}><FranchizeFigure onLeaveApplication={props.onLeaveApplication} /></div>
      </div>
    </section>
  );
};

export default investments;