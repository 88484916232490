import React, {Component} from 'react';
import {Element} from 'react-scroll';
import Slider from 'react-slick';

import classes from './News.module.scss';
import Aux from '../../hoc/Aux/Aux';
import Title from '../../components/UI/Title/Title';
import Modal from '../../components/UI/Modal/Modal';
import close from '../../assets/images/gamesPage/close.svg';

class News extends Component {
  state = {
    isShowModal: false,
    selectedNewsItem: {},
    width: 0
  };

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }
  
  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth });
  }

  openModal = (e, item) => {
    if (e.target.tagName === 'A') {
      return;
    } else if (this.state.width >= 768 || this.props.path === '/') {
      this.setState({
        isShowModal: !this.state.isShowModal,
        selectedNewsItem: item
      });
      document.querySelector('body').style.overflow = 'hidden';
    }
  }

  closeModal = () => {
    this.setState({
      isShowModal: !this.state.isShowModal,
      selectedNewsItem: {}
    });
    document.querySelector('body').style.overflow = '';
  }

  renderItems = () => {
    return this.props.news.map(newsItem => {
      return (
        <li key={newsItem.id} className={classes.Item} onClick={(e) => this.openModal(e, newsItem)}>
          <p className={classes.Time}>{newsItem.description.subtitle}</p>
          <p className={classes.Poster}><img src={newsItem.images_list.square} alt={newsItem.description.title} /></p>
          <p className={classes.Title}>{newsItem.description.title}</p>
          <p className={classes.Description} dangerouslySetInnerHTML={{__html: newsItem.description.description}}/>
        </li>
      );
    });
  }

  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false
    };
    let news = (
      <ul className={classes.List}>
        {this.renderItems()}
      </ul>
    );

    if (this.state.width <= 1024 && this.props.news.length && this.props.path === '/') {
      news = (
        <div className={classes.Slider}>
          <Slider {...settings} className={classes.List}>
            {this.renderItems()}
          </Slider>
        </div>
      );
    } else if (this.state.width < 768 && this.props.path === '/news') {
      news = (
        <ul className={classes.NewsList}>
          {this.renderItems()}
        </ul>
      );
    }
    const {selectedNewsItem} = this.state;

    let newsInfo;
    if (Object.keys(selectedNewsItem).length !== 0) {
      newsInfo = (
        <Aux>
          <div className={classes.ModalContent}>
            <Element className={classes.Scroll}>
              <p className={classes.Subtitle}>{selectedNewsItem.description.subtitle}</p>
              <p className={classes.Poster}><img src={selectedNewsItem.images_list.square} alt={selectedNewsItem.description.title} /></p>
              <p className={classes.Title}>{selectedNewsItem.description.title}</p>
              <p className={classes.Description} dangerouslySetInnerHTML={{__html: selectedNewsItem.description.description}} />
            </Element>
            <div className={classes.ModalClose}><img src={close} alt={'close'} onClick={() => this.closeModal()} /></div>
          </div>
        </Aux>
      );
    }
    
    return (
      <Aux>
        <Title>{this.props.t('mainPage.newsSection.title')}</Title>
        {news}
        {
          this.state.isShowModal ?
            <Modal 
              show={this.state.isShowModal} 
              closed={() => this.closeModal()} 
              smallWidth={true}
            >{newsInfo}</Modal> : null
        }
      </Aux>
    );
  }
};

export default News;