import React, {Component} from 'react';
import { withTranslation } from 'react-i18next';

import classes from './Breadcrumbs.module.scss';
import backBtnImg from '../../../assets/images/roomPage/back-btn.svg';

class Breadcrumbs extends Component {
  goToMain = () => {
    this.props.goToMain();
  }

  goBack = () => {
    this.props.goBack();
  };

  render() {
    const { t } = this.props;

    return(
      <ul className={classes.Breadcrumbs}>
        <li className={classes.Back} onClick={() => this.goBack()}>
          <img src={backBtnImg} alt={'back'} />
        </li>
        <li 
          className={classes.MobileOnly} 
          onClick={() => this.goToMain()}
        >
          <span className={classes.Pointer}>{t('breadcrumb.pointer')}</span> /&nbsp;
        </li>
        { 
          this.props.address ? 
            <li className={classes.MobileOnly} onClick={() => this.goBack()}>
              <span className={classes.Pointer}>{this.props.address}</span> /&nbsp;
            </li> : null
        }
        <li className={classes.Room}>
          {this.props.room}
        </li>
      </ul>
    );
  }; 
};

const BreadcrumbsTranslation = withTranslation()(Breadcrumbs);

export default BreadcrumbsTranslation;