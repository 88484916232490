import React, {Component} from 'react';
import { withRouter } from 'react-router-dom';

import mainLogo from '../../assets/images/mainPage/logo.svg';
import classes from './Logo.module.scss';

class Logo extends Component {
  redirectToMain = () => {
    this.props.history.push({pathname: '/'});
    window.scrollTo(0, 0);
  };

  render() {
    return (
      <div className={classes.Logo} onClick={() => this.redirectToMain()}>
        <img src={mainLogo} alt="logo" />
      </div>
    );
  };
};

export default withRouter(Logo);