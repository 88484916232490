import axios from 'axios';

const { NODE_ENV } = process.env;

let baseURL = NODE_ENV === 'development' ? 'https://plays.gbsfo.com' : 'https://plays.eatery.club';

const instance = axios.create({
  baseURL,
});

export default instance;