import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
  categories: [],
  activeCategory: null
};

const fetchGames = (state, action) => {
  return updateObject(state);
};

const fetchGamesSuccess = (state, action) => {
  let categories = [];
  const allGames = [];
  const uniqueArr = [];
  const map = new Map();

  action.categories.forEach(cat => {
    categories.push({
      id: cat.id + 1,
      name: cat.description.title,
      isActive: false,
      games: cat.games
    });
    allGames.push(cat.games)
  });

  const flattenAllGames = allGames.flat();

  for (const item of flattenAllGames) {
    if(!map.has(item.id)) {
      map.set(item.id, true);
      uniqueArr.push({...item});
    }
  }

  categories.unshift({id: 1, name: 'Все', isActive: true, games: uniqueArr});

  const activeCategory = categories.find(cat => cat.isActive);

  return updateObject(state, {categories, activeCategory});
}

const changeActiveCategory = (state, action) => {
  const categories = state.categories.map(cat => cat.id === action.category.id ? {...cat, isActive: true} : {...cat, isActive: false});
  const activeCategory = categories.find(cat => cat.isActive);

  return updateObject(state, {categories, activeCategory});
}

const reducer = (state = initialState, action) => {
	switch(action.type) {
    case actionTypes.FETCH_GAMES: return fetchGames(state, action);
    case actionTypes.FETCH_GAMES_SUCCESS: return fetchGamesSuccess(state, action);
    case actionTypes.CHANGE_ACTIVE_CATEGORY: return changeActiveCategory(state, action);
		default: return state;
	}
};

export default reducer;