import React from 'react';

import classes from './BoardGame.module.scss';

const boardGame = (props) => {
  const { game, t } = props;

  return (
    <li className={classes.Item}>
      <div className={classes.Left}><img src={game.img} alt={game.name} /></div>
      <div className={classes.Right}>
        <p className={classes.Title}>{game.name}</p>
        <p className={classes.Players}>{t('gamesPage.boardGames.players')} <span>{game.countPlayers}</span></p>
      </div>
    </li>
  );
};

export default boardGame;