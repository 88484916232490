import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import classes from './AboutAppPage.module.scss';
import favouriteGuestPhoneImg from '../../assets/images/aboutAppPage/favourite-guest.png';
import accumulatePhoneImg from '../../assets/images/aboutAppPage/accumulate.png';
import accumulatedPhoneImg from '../../assets/images/aboutAppPage/accumulated.png';
import BalancePhoneImg from '../../assets/images/aboutAppPage/balance.png';

import Title from '../../components/UI/AboutTitle/AboutTitle';
import Description from '../../components/UI/AboutDescription/AboutDescription';
import Links from '../../components/Download/Links/Links';
import Download from '../../components/Download/Download';
import Subtitle from '../../components/UI/Subtitle/Subtitle';

class AboutAppPage extends Component {
  render() {
    const { t } = this.props;
    const levels = [
      {
        subtitle: t('aboutAppPage.accumulatePointsSection.levels.firstLevel.subtitle'),
        cashback: t('aboutAppPage.accumulatePointsSection.levels.firstLevel.cashback')
      },
      {
        subtitle: t('aboutAppPage.accumulatePointsSection.levels.secondLevel.subtitle'),
        cashback: t('aboutAppPage.accumulatePointsSection.levels.secondLevel.cashback'),
        transition: t('aboutAppPage.accumulatePointsSection.levels.secondLevel.transition')
      },
      {
        subtitle: t('aboutAppPage.accumulatePointsSection.levels.thirdLevel.subtitle'),
        cashback: t('aboutAppPage.accumulatePointsSection.levels.thirdLevel.cashback'),
        transition: t('aboutAppPage.accumulatePointsSection.levels.thirdLevel.transition')
      }
    ];

    return (
      <div className={classes.About}>
        <div className={classes.Wrapper}>
          <div className={classes.Container}>
            <div className={classes.InnerContainer}>
              <section className={classes.FavouriteGuest}>
                <div className={classes.Left}>
                  <Title>{t('aboutAppPage.bonusSystemSection.title')}</Title>
                  <Description>{t('aboutAppPage.bonusSystemSection.description')}</Description>
                  <Links />
                </div>
                <img className={classes.Right} src={favouriteGuestPhoneImg} alt={'favourite guest phone img'} />
              </section>
            </div>
          </div>
        </div>
        <div className={classes.InnerContainer}>
          <section className={classes.AccumulatePoints}>
            <img className={classes.Left} src={accumulatePhoneImg} alt={''} />
            <div className={classes.Right}>
              <Title>{t('aboutAppPage.accumulatePointsSection.title')}</Title>
              <Description>{t('aboutAppPage.accumulatePointsSection.description')}</Description>
              <ul className={classes.List}>
                {
                  levels.map(level => {
                    return (
                      <li key={level.subtitle} className={classes.Item}>
                        <Subtitle>{level.subtitle}</Subtitle>
                        <Description>{level.cashback}</Description>
                        <Description>{level.transition}</Description>
                      </li>
                    )
                  })
                }
              </ul>
            </div>
          </section>

          <section className={classes.Balance}>
            <div className={classes.Left}>
              <Title>{t('aboutAppPage.balanceSection.title')}</Title>
              <Description>{t('aboutAppPage.balanceSection.description')}</Description>
            </div>
            <img className={classes.Right} src={BalancePhoneImg} alt={''} />
          </section>

          <section className={classes.AccumulatedPoints}>
            <img className={classes.Left} src={accumulatedPhoneImg} alt={''} />
            <div className={classes.Right}>
              <Title>{t('aboutAppPage.accumulatedPointsSection.title')} <span>{t('aboutAppPage.accumulatedPointsSection.markedTitle')}</span></Title>
              <Description>{t('aboutAppPage.accumulatedPointsSection.description')}</Description>
            </div>
          </section>
          <Download t={t} />
        </div>
      </div>
    );
  }
};

const AboutAppPageTranslation = withTranslation()(AboutAppPage);

export default AboutAppPageTranslation;