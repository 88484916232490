import React, {Component} from 'react';

import classes from './Location.module.scss';

class Location extends Component {
  selectLocation = (e, id) => {
    if (!e.target.href) {
      this.props.clicked(id);
    }
  }

  phone = (tel) => {
    return 'tel:' + tel;
  }

  render() {
    const {location, t} = this.props;
    return(
      <li 
        className={[classes.Item, classes[location.description.title]].join(' ')} 
        onClick={(e) => this.selectLocation(e, location.id)}
        style={{
          backgroundImage: `url(${location.images_list.logo})`
        }}
      >
        <h3 className={classes.Title}>{location.description.title}</h3>
        <p className={classes.Street}>{location.description.address}</p>
        <p className={classes.Time}>{location.current_work_time}</p>
        <ul className={classes.Phone}>
          {
            location.telephones.map(tel => {
              return (
                <li key={tel}>
                  <a href={this.phone(tel)}>{tel}</a>            
                </li>
              )
            })
          }
        </ul>
        <p className={classes.Route}>
          <a 
            target="_blank" 
            rel="noopener noreferrer" 
            href={`https://www.google.com/maps/@${location.latitude},${location.longitude},17z/${location.google_cid}`}
          >{t('mainPage.locationsSection.showMap')}</a>
        </p>
      </li>
    );
  };
};

export default Location;