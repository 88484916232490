import React, {Component} from 'react';

import classes from './Games.module.scss';

import Aux from '../../hoc/Aux/Aux';
import Game from './Game/Game';

class Games extends Component {
  render() {
    const {games} = this.props;

    return(
      <Aux>
        <ul className={classes.List}>
          {
            games.map(game => (
              <Game 
                key={game.id} 
                game={game} 
                onOpenModal={this.props.onOpenModal} />
            ))
          }
        </ul>
      </Aux>
    );
  }
};

export default Games;