import React from 'react';

import classes from './SocialBtn.module.scss';

const socialBtn = (props) => {
  return(
    <li>
      <a 
        href={props.navTo} 
        className={[classes.SocialBtn, classes[props.children]].join(' ')}
        target="_blank"
        rel="noopener noreferrer"
      >{props.children}</a>
    </li>
  );
}

export default socialBtn;