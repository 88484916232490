import React from 'react';

import classes from './Shop.module.scss';

const shop = (props) => {
  return(
    <ul className={classes.List}>
      {props.shop.map((item, idx) => {
        return (
          <li key={idx} className={classes.Item}>
            <p className={classes.Price}>Цена - <span>{item.price}</span></p>
            <p className={classes.Poster}><img src={item.img} alt={item.title} /></p>
            <p className={classes.Title}>{item.title}</p>
            <p className={classes.Description}>{item.description}</p>
          </li>
        );
      })}
    </ul>
  );
};

export default shop;