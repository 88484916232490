import React from 'react';
import { withRouter } from 'react-router-dom';

import classes from './NewsSection.module.scss';
import Aux from '../../hoc/Aux/Aux';
import Button from '../UI/Button/Button';
import News from '../News/News';

const newsSection = (props) => (
  <Aux>
    <section className={classes.News}>
      <div className={classes.Container}>
        <News news={props.news} path={props.match.path} t={props.t} />
        <p className={classes.ShowMore}>
          <Button btnColor="Transparent" clicked={() => props.onRedirectToNews()}>{props.t('mainPage.newsSection.showAllBtn')}</Button>
        </p>
      </div>
    </section>
  </Aux>
);

export default withRouter(newsSection);