import React, {Component} from 'react';

import classes from './Categories.module.scss';
import Category from './Category/Category';

class Categories extends Component {
  constructor(props) {
    super(props);
    this.state = { width: 0, isShow: false };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }
  
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }
  
  updateWindowDimensions() {
    this.setState({ width: window.innerWidth });
  }

  toggleShow = () => {
    this.setState({isShow: !this.state.isShow});
  }

  render() {
    let activeCategory;
    if (this.state.width < 1025 && this.props.categories.length) {
      activeCategory = this.props.categories.filter(cat => {
        return cat.isActive;
      });
    }

    return(
      <div className={classes.Categories}>
        {activeCategory ? <p className={classes.ActiveCategory} onClick={() => this.toggleShow()}>{activeCategory[0].name}</p> : null}
        {
          this.state.isShow || this.state.width >= 1025 ?
            <ul className={classes.List}>
              {
                this.props.categories.map((cat, catIdx) => {
                  return(
                    <Category 
                      key={catIdx} 
                      category={cat} 
                      closed={() => this.toggleShow()} 
                      first={this.props.categories[0].name} 
                    />
                  );
                })
              }
            </ul> : null
        }
      </div>
    );
  }
};

export default Categories;