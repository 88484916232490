import React, { Component } from 'react';

import classes from './Popup.module.scss';
import Aux from '../../../hoc/Aux/Aux';
import Backdrop from '../Backdrop/Backdrop';

class Popup extends Component {
  render () {
    return (
      <Aux>
        <Backdrop show={this.props.show} clicked={this.props.closed}/>
        <div 
          className={classes.Popup}
          style={{
            transform: this.props.show ? 'translate(-50%, -50%)' : 'translate(100vh, -100vw)',
            opacity: this.props.show ? '1' : '0'
          }} >
            {this.props.children}
        </div>
      </Aux>
    );
  }
};

export default Popup;