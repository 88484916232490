import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
  token: '',
};

const fetchGuestUserSuccess = (state, action) => {
  const { token } = action;

  return updateObject(state, {
    token,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_GUEST_USER_SUCCESS: return fetchGuestUserSuccess(state, action);
    default: return state;
  }
};

export default reducer;