import React, {Component} from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import MainPage from './containers/MainPage/MainPage';
import GamesPage from './containers/GamesPage/GamesPage';
import StocksPage from './containers/StocksPage/StocksPage';
import ShopPage from './containers/ShopPage/ShopPage';
import AboutAppPage from './containers/AboutAppPage/AboutAppPage';
import LocationPage from './containers/LocationPage/LocationPage';
import RoomPage from './containers/RoomPage/RoomPage';
import Layout from './hoc/Layout/Layout';
import NewsPage from './containers/NewsPage/NewsPage';
import FranchizePage from './containers/FranchizePage/FranchizePage';

import classes from  './App.module.scss';

class App extends Component {
  
  render() {
    let routes = (
      <Layout>
        <Switch>
          <Route path="/franchise" component={FranchizePage} />
          <Route path="/news" component={NewsPage} />
          <Route path="/about" component={AboutAppPage} />
          {/* <Route path="/shop" component={ShopPage} /> */}
          <Route path="/stocks" component={StocksPage} />
          <Route path="/games" component={GamesPage} />
          <Route path="/:locationId/:roomId" component={RoomPage} />
          <Route path="/:locationId" component={LocationPage} />
          <Route path="/" exact component={MainPage} />
          <Redirect to="/" />
        </Switch>
      </Layout>
    );
    return (
      <div className={classes.App}>
        {routes}
      </div>
    );
  }
}

export default App;
