import React from 'react';
import InputMask from 'react-input-mask';

import classes from './Input.module.scss';
import Selecter from '../Select/Select';
import DatePicker from '../DatePicker/DatePicker';
import TimePicker from '../TimePicker/TimePicker';

const input = (props) => {
  let inputElement = null;
  const inputClasses = [classes.Input];

  if (props.invalid && props.shouldValidate && props.touched) {
    inputClasses.push(classes.Invalid);
  }

  switch (props.elementType) {
    case ('input'):
      inputElement = <input 
        className={inputClasses.join(' ')} 
        {...props.elementConfig} 
        value={props.value} 
        onChange={props.changed}
        onBlur={props.blur}
      />;
      break;
    case ('select'):
      inputElement = <Selecter
        className={inputClasses.join(' ')} 
        {...props.elementConfig} 
        value={props.value} 
        onChange={props.changed}
        onBlur={props.blur}
      />;
      break;
    case ('date'):
      inputElement = <DatePicker 
        className={inputClasses.join(' ')}
        {...props.elementConfig} 
        value={props.value} 
        onChange={props.changed}
        onBlur={props.blur}
        currentLang={props.currentLang}
      />;
      break;
    case ('time'):
      inputElement = <TimePicker 
        className={inputClasses.join(' ')}
        {...props.elementConfig} 
        value={props.value} 
        onChange={props.changed}
        onBlur={props.blur}
      />;
      break;
    case ('phone'):
      inputElement = <InputMask 
        className={inputClasses.join(' ')} 
        {...props.elementConfig} 
        value={props.value} 
        onChange={props.changed}
        mask="+380 99 999 99 99"
        maskChar=' '
      />;
      break;
    default:
      return;
  }

  return (
    <div className={classes.Input}>
      <label className={classes.Label}>{props.label}</label>
      {inputElement}
    </div>
  );
};

export default input;