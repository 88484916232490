import React from 'react';
import { NavLink, Link } from 'react-router-dom';

import classes from './NavigationItem.module.scss';
import file from '../../../../../assets/documents/menu.pdf';

const navigationItem = (props) => {
  return (
    <li className={classes.NavigationItem}>
      {
        props.link === '/menu' ?
          <Link 
            to={file}
            target="_blank"
            className={props.className}
          >{props.children}</Link> : 
          <NavLink 
            to={props.link}
            exact={props.exact}
            activeClassName={classes.active}
            className={props.className}
            onClick={props.closed}>{props.children}</NavLink>
      }
    </li>
  )
};

export default navigationItem;