import React, {Component} from 'react';

import classes from './BoardGames.module.scss';

import BoardGame from './BoardGame/BoardGame';
import monopolyImg from '../../assets/images/gamesPage/boardGames/monopoly.svg';
import unoImg from '../../assets/images/gamesPage/boardGames/uno.svg';
import bangImg from '../../assets/images/gamesPage/boardGames/bang.svg';
import jengaImg from '../../assets/images/gamesPage/boardGames/jenga.svg';
import conflictCardImg from '../../assets/images/gamesPage/boardGames/conflictCard.svg';
import imajinariumImg from '../../assets/images/gamesPage/boardGames/imajinarium.svg';

class BoardGames extends Component {
  render() {
    const { t } = this.props;
    const boardGames = [
      {name: t('gamesPage.boardGames.monopoly'), img: monopolyImg, countPlayers: '‎2–8'},
      {name: t('gamesPage.boardGames.uno'), img: unoImg, countPlayers: '‎2–10'},
      {name: t('gamesPage.boardGames.bang'), img: bangImg, countPlayers: '‎4-7'},
      {name: t('gamesPage.boardGames.jenga'), img: jengaImg, countPlayers: '‎1–12'},
      {name: t('gamesPage.boardGames.conflict'), img: conflictCardImg, countPlayers: '3-16'},
      {name: t('gamesPage.boardGames.imajinarium'), img: imajinariumImg, countPlayers: '‎4–7'},
    ];

    return(
      <ul className={classes.List}>
        {
          boardGames.map((game, gameIdx) => (
            <BoardGame key={gameIdx} game={game} t={t} />
          ))
        }
      </ul>
    );
  }
};

export default BoardGames;