import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Element } from 'react-scroll';
import { withTranslation } from 'react-i18next';

import classes from './GamesPage.module.scss'

import Title from '../../components/UI/Title/Title';
import Categories from '../../components/Games/Categories/Categories';
import Games from '../../components/Games/Games';
import BoardGames from '../../components/BoardGames/BoardGames';
import Modal from '../../components/UI/Modal/Modal';
import Aux from '../../hoc/Aux/Aux';
import close from '../../assets/images/gamesPage/close.svg';

import * as actions from '../../store/actions/index';

class GamesPage extends Component {
  state = {
    isShowModal: false,
    selectedGame: {}
  };

  componentDidMount() {
    const { currentLang, onFetchGames } = this.props;
    onFetchGames(currentLang);
  }

  componentDidUpdate() {
    if (window.innerWidth < 1025) {
      window.onpopstate = (e) => {
        this.props.history.push({ pathname: '/games' });
        this.closeModal();
      }
    }
  }

  closeModal = () => {
    this.setState({ isShowModal: false });
    document.querySelector('body').style.overflow = '';
  }

  openModal = (id) => {
    const foundGame = this.props.gamesReducer.activeCategory.games.find(game => game.id === id);
    this.setState({ isShowModal: true, selectedGame: foundGame });
    document.querySelector('body').style.overflow = 'hidden';
  }

  render() {
    const { t } = this.props;
    const { selectedGame } = this.state;
    const { categories, activeCategory } = this.props.gamesReducer;
    let gameInfo;

    if (Object.entries(selectedGame).length !== 0) {
      const availableIn = selectedGame.restaurants.map(rest => rest.description.title);
      gameInfo = (
        <Aux>
          <div className={classes.ModalContent}>
            <div className={classes.ModalLeft}>
              <img src={selectedGame.image} alt={selectedGame.description.title} />
            </div>
            <div className={classes.ModalRight}>
              <h4 className={classes.ModalTitle}>{selectedGame.description.title}</h4>
              <Element className={classes.Scroll}>
                <p className={classes.ModalDescription}>{selectedGame.description.description}</p>
              </Element>
              {/* <p className={classes.ModalConsole}>Консоль: <span>{selectedGame.platforms.join(', ')}</span></p> */}
              <p className={classes.ModalJoystick}>{t('gamesPage.modal.joystickCount')} <span>{selectedGame.max_players_count}</span></p>
              <p className={classes.ModalAvailable}>{t('gamesPage.modal.available')} <span>{availableIn.join(', ')}</span></p>
            </div>
            <div className={classes.ModalClose}><img src={close} alt={'close'} onClick={() => this.closeModal()} /></div>
          </div>
        </Aux>
      );
    }

    return (
      <Aux>
        <div className={classes.Wrapper}>
          <div className={classes.Container}>
            <div className={classes.Content}>
              <div className={classes.Left}>
                <div className={classes.Top}>
                  <Title>{t('gamesPage.title')}</Title>
                  <Categories categories={categories} />
                </div>
                <div className={classes.Bottom}>
                  {
                    activeCategory ?
                      <Games
                        games={activeCategory.games}
                        onOpenModal={(id) => this.openModal(id)}
                      /> : null
                  }
                </div>
              </div>
              <aside className={classes.Right}>
                <h3 className={classes.BoardGamesTitle}>{t('gamesPage.boardGames.title')}</h3>
                <BoardGames t={t} />
              </aside>
            </div>
          </div>
          <Modal show={this.state.isShowModal} closed={() => this.closeModal()}>{gameInfo}</Modal>
        </div>
      </Aux>
    );
  };
};

const mapStateToProps = state => {
  return {
    gamesReducer: {
      boardGames: state.gamesReducer.boardGames,
      categories: state.gamesReducer.categories,
      activeCategory: state.gamesReducer.activeCategory
    },
    currentLang: state.localizationReducer.currentLang,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onFetchGames: (lang) => dispatch(actions.initGames(lang))
  };
};

const GamesPageTranslation = withTranslation()(GamesPage);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GamesPageTranslation));
