import React from 'react';

import classes from './Stocks.module.scss';
import Aux from '../../hoc/Aux/Aux';

const stocks = (props) => {
  const {totalStocks, stocks} = props;
  return(
    <Aux>
      <ul className={classes.List}>
        {stocks.map(stock => {
          return (
            <li key={stock.id} className={classes.Item}>
              <div className={classes.Wrapper}>
                <p className={classes.How}>{stock.description.subtitle}</p>
              </div>
              <p className={classes.Poster}><img src={stock.images_list.original} alt={stock.description.title} /></p>
              <p className={classes.Title}>{stock.description.title}</p>
              <p className={classes.Description}>{stock.description.description}</p>
            </li>
          );
        })}
      </ul>
      {
        totalStocks > stocks.length ?
          <p className={classes.ShowMore} onClick={props.showMore}>Показать больше акций</p> : null
      }
    </Aux>
  );
};

export default stocks;