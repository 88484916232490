import React, {Component} from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import classes from './LocationPage.module.scss';

import Breadcrumbs from '../../components/UI/Breadcrumbs/Breadcrumbs';
import Address from '../../components/Address/Address';

import * as actions from '../../store/actions/index';

class LocationPage extends Component {
  componentDidMount() {
    this.props.onSelectedLocation(+this.props.match.params.locationId);
  }

  selectedRoom = (room) => {
    this.props.history.push({pathname: this.props.location.pathname + '/' + room.id})
    this.props.onSelectRoom(room.id);
    window.scrollTo(0, 0);
  }

  goToMain = () => {
    this.props.history.goBack();
  }

  render() {
    return(
      <section className={classes.Wrapper}>
        <div className={classes.Container}>
          {
            this.props.selectedLocation ?
              <div className={classes.Content}>
                <Breadcrumbs 
                  room={this.props.selectedLocation.description.address} 
                  goToMain={() => this.goToMain()} 
                  goBack={() => this.goToMain()}
                />
                <Address address={this.props.selectedLocation} clicked={(room) => this.selectedRoom(room)} />
              </div> : null
          }
        </div>
      </section>
    );
  };
};

const mapStateToProps = state => {
  return {
    selectedLocation: state.locationsReducer.selectedLocation,
    selectedRoom: state.locationsReducer.selectedRoom
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onSelectRoom: (id) => dispatch(actions.selectRoom(id)),
    onSelectedLocation: (id) => dispatch(actions.selectedLocation(id))
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LocationPage));
