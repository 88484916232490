import React, { Component } from 'react';

import classes from './Modal.module.scss';
import Aux from '../../../hoc/Aux/Aux';
import Backdrop from '../Backdrop/Backdrop';

class Modal extends Component {
  render () {
    let classNames = [classes.Modal];
    
    if (this.props.smallWidth) {
      classNames = [classes.Modal, classes.SmallWidth];
    }
    
    return (
      <Aux>
        <Backdrop show={this.props.show} clicked={this.props.closed}/>
        <div 
          className={classNames.join(' ')}
          style={{
            transform: this.props.show ? 'translate(-50%, -50%)' : 'translate(-50%, -50%)',
            opacity: this.props.show ? '1' : '0',
            zIndex: this.props.show ? '200' : '100'
          }} >
            {this.props.children}
        </div>
      </Aux>
    );
  }
};

export default Modal;