import * as actionTypes from './actionTypes';
import { fetchLocations, fetchNews, initGames, fetchStocks, selectedLocation, selectedRoom } from './index';

export const changeLang = (lang, pathname, location = null, room = null) => {
  return dispatch => {
    switch (pathname) {
      case '/':
        dispatch(fetchLocations(lang));
        dispatch(fetchNews(lang, 3));
        break;
      case '/games':
        dispatch(initGames(lang));
        break;
      case '/stocks':
        dispatch(fetchStocks(lang, 6));
        break;
      case '/news':
        dispatch(fetchNews(lang, 6));
        break;
      default: break;
    }

    if (location) {
      dispatch(selectedLocation(location.id));
    }

    if (room) {
      dispatch(selectedRoom(room.id));
    }

    dispatch(changeLangSuccess(lang));
  };
};

export const changeLangSuccess = (lang) => {
  return {
    type: actionTypes.CHANGE_LANG_SUCCESS,
    lang
  };
};