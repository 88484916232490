import React, { Component } from 'react';
import { Element } from 'react-scroll';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import i18n from "i18next";

import Aux from '../Aux/Aux';
import classes from './Layout.module.scss';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import SideDrawer from '../../components/Header/Navigation/NavigationItems/SideDrawer/SideDrawer';
import Modal from '../../components/UI/Modal/Modal';
import Popup from '../../components/UI/Popup/Popup';
import Input from '../../components/UI/Input/Input';
import close from '../../assets/images/gamesPage/close.svg';
import Button from '../../components/UI/Button/Button';
import { updateObject, checkValidity } from '../../shared/utility';
import * as actions from '../../store/actions/index';

class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: 0,
      showSideDrawer: false,
      isShowModal: false,
      isShowPopup: false,
      reserveForm: {
        place: {
          elementType: 'select',
          label: 'Місце',
          elementConfig: {
            options: [],
            placeholder: 'Виберіть місце'
          },
          value: '',
          validation: {
            isNumeric: true
          },
          valid: false,
          touched: false
        },
        room: {
          elementType: 'select',
          label: 'Кімната',
          elementConfig: {
            options: [],
            placeholder: 'Виберіть кімнату',
            disabled: true
          },
          value: '',
          validation: {
            isNumeric: true
          },
          valid: false,
          touched: false
        },
        date: {
          elementType: 'date',
          label: 'Дата',
          elementConfig: {
            type: 'date',
            placeholder: 'Виберіть дату і час'
          },
          value: '',
          validation: {
            required: true
          },
          valid: false,
          touched: false
        },
        firstName: {
          elementType: 'input',
          label: "Ім'я",
          elementConfig: {
            type: 'text',
            placeholder: "Ваше ім'я"
          },
          value: '',
          validation: {
            required: true,
            minLength: 2,
            maxLength: 10,
            withoutSpecialSymbols: true
          },
          valid: false,
          touched: false
        },
        lastName: {
          elementType: 'input',
          label: 'Прізвище',
          elementConfig: {
            type: 'text',
            placeholder: 'Ваше прізвище'
          },
          value: '',
          validation: {
            required: true,
            minLength: 2,
            maxLength: 10,
            withoutSpecialSymbols: true
          },
          valid: false,
          touched: false
        },
        phone: {
          elementType: 'phone',
          label: 'Номер',
          elementConfig: {
            type: 'tel',
            placeholder: '+380 00 000 00 00'
          },
          value: '',
          validation: {
            required: true,
            minLength: 13,
            maxLength: 13
          },
          valid: false,
          touched: false
        }
      },
      formIsValid: false,
      phone: '',
      currentLang: 'uk',
      lang: {
        elementType: 'select',
        elementConfig: {
          options: [
            {
              id: 1,
              value: 'ru',
              displayValue: 'Русский'
            },
            {
              id: 2,
              value: 'en',
              displayValue: 'English'
            },
            {
              id: 3,
              value: 'uk',
              displayValue: 'Українська'
            }
          ],
        },
        value: '',
      }
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    const { currentLang, onFetchLocations, onFetchGuestUser } = this.props;
    onFetchGuestUser();
    onFetchLocations(currentLang);
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentDidUpdate(prevProps, prevState) {
    const { t } = this.props;
    const reserveFormClone = { ...this.state.reserveForm };

    if (prevState.currentLang !== this.state.currentLang) {
      for (let formElementIdentifier in reserveFormClone) {
        switch (formElementIdentifier) {
          case 'place':
            reserveFormClone[formElementIdentifier].label = t('reserveModal.form.place.label');
            reserveFormClone[formElementIdentifier].elementConfig.placeholder = t('reserveModal.form.place.placeholder');
            break;
          case 'room':
            reserveFormClone[formElementIdentifier].label = t('reserveModal.form.room.label');
            reserveFormClone[formElementIdentifier].elementConfig.placeholder = t('reserveModal.form.room.placeholder');
            break;
          case 'date':
            reserveFormClone[formElementIdentifier].label = t('reserveModal.form.date.label');
            reserveFormClone[formElementIdentifier].elementConfig.placeholder = t('reserveModal.form.date.placeholder');
            break;
          case 'firstName':
            reserveFormClone[formElementIdentifier].label = t('reserveModal.form.firstName.label');
            reserveFormClone[formElementIdentifier].elementConfig.placeholder = t('reserveModal.form.firstName.placeholder');
            break;
          case 'lastName':
            reserveFormClone[formElementIdentifier].label = t('reserveModal.form.lastName.label');
            reserveFormClone[formElementIdentifier].elementConfig.placeholder = t('reserveModal.form.lastName.placeholder');
            break;
          case 'phone':
            reserveFormClone[formElementIdentifier].label = t('reserveModal.form.phone.label');
            break;
          default: break;
        }
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    const reserveFormClone = { ...this.state.reserveForm };
    if (nextProps.locations.length) {
      const optionValues = nextProps.locations.map(loc => {
        return {
          id: loc.id,
          value: loc.description.address,
          displayValue: loc.description.address
        }
      });

      reserveFormClone.place.elementConfig.options = optionValues;
    }
    if (nextProps.reserveFormSelectedLocation) {
      const optionValues = [];
      nextProps.reserveFormSelectedLocation.rooms.forEach(loc => {
        if (loc.bookable) {
          optionValues.push({
            id: loc.id,
            value: loc.description.title,
            displayValue: loc.description.title,
            countPlayers: `${loc.min_players}-${loc.max_players}`
          });
        }
      });

      reserveFormClone.room.elementConfig.options = optionValues;
      reserveFormClone.room.elementConfig.disabled = false;
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth });
  }

  sideDrawerClosedHandler = () => {
    document.querySelector('body').style.overflow = '';
    this.setState({ showSideDrawer: false });
  };

  sideDrawerToggleHandler = () => {
    document.querySelector('body').style.overflow = 'hidden';
    this.setState((prevState) => {
      return {
        showSideDrawer: !prevState.showSideDrawer
      };
    });
  };

  openReserveModal = () => {
    document.querySelector('body').style.overflow = 'hidden';
    this.setState({ isShowModal: true });
    this.resetForm();
  };

  closeReserveModal = () => {
    document.querySelector('body').style.overflow = '';
    this.setState({ isShowModal: false });
    this.resetForm();
  };

  togglePopup = () => {
    this.setState({ isShowPopup: !this.state.isShowPopup });
  }

  resetForm = () => {
    const obj = { ...this.state.reserveForm };

    for (let formElementIdentifier in obj) {
      if (formElementIdentifier === 'room') {
        obj[formElementIdentifier].elementConfig.options = [];
        obj[formElementIdentifier].elementConfig.disabled = true;
      }
      obj[formElementIdentifier].value = '';
      obj[formElementIdentifier].touched = false;
      obj[formElementIdentifier].valid = false;
    }

    this.setState({ reserveForm: obj, formIsValid: false });
  }

  reserveHandler = (e) => {
    const { token, onBooking } = this.props;
    const reserve = {};

    for (let formElementIdentifier in this.state.reserveForm) {
      reserve[formElementIdentifier] = this.state.reserveForm[formElementIdentifier].value;
    }

    onBooking(token, reserve);
    this.closeReserveModal();
    this.resetForm();
    this.togglePopup();
  }

  updateFormElement = (value, inputIdentifier) => {
    return updateObject(this.state.reserveForm[inputIdentifier], {
      value,
      valid: checkValidity(value, this.state.reserveForm[inputIdentifier].validation),
      touched: true
    });
  }

  updateReserveForm = (inputIdentifier, updatedFormElement) => {
    const udpatedReserveForm = { ...this.state.reserveForm };
    udpatedReserveForm[inputIdentifier] = updatedFormElement;

    let formIsValid = true;

    for (let inputIdentifier in udpatedReserveForm) {
      formIsValid = udpatedReserveForm[inputIdentifier].valid && formIsValid;
    }

    this.setState({ reserveForm: udpatedReserveForm, formIsValid });
  }

  inputChangedHandler = (event, inputIdentifier) => {
    let updatedFormElement;
    if (inputIdentifier === 'place' || inputIdentifier === 'room') {
      if (inputIdentifier === 'place') {
        this.props.onReserveFormSelectedLocation(event);
      }
      updatedFormElement = this.updateFormElement(event, inputIdentifier);
    } else if (inputIdentifier === 'date') {
      updatedFormElement = this.updateFormElement(event.format('YYYY-MM-DD HH:mm:ss'), inputIdentifier);
    } else if (inputIdentifier === 'phone') {
      updatedFormElement = this.updateFormElement(this.formatPhoneNumber(event.target.value), inputIdentifier);
    } else {
      updatedFormElement = this.updateFormElement(event.target.value, inputIdentifier);
    }

    this.updateReserveForm(inputIdentifier, updatedFormElement);
  }

  formatPhoneNumber(phone = '') {
    return (phone).trim().split(' ').join('');
  }

  blurHandler = (event, inputIdentifier) => {
    let updatedFormElement;
    const value = event.target.value;
    updatedFormElement = this.updateFormElement(value, inputIdentifier);

    this.updateReserveForm(inputIdentifier, updatedFormElement);
  }

  changeLang = lang => {
    const { selectedLocation, selectedRoom } = this.props;
    this.props.onChangeLang(lang, this.props.location.pathname, selectedLocation, selectedRoom ? selectedRoom.rooms : null);
    i18n.changeLanguage(lang);
    this.setState({ currentLang: lang });
  }

  render() {
    const formElementsArray = [];
    const { t } = this.props;

    for (let key in this.state.reserveForm) {
      formElementsArray.push({
        id: key,
        config: this.state.reserveForm[key]
      });
    }

    let form;
    if (this.state.isShowModal) {
      form = (
        <div className={classes.ModalContent}>
          <p className={classes.ModalTitle}>{t('reserveModal.title')}</p>
          <form onSubmit={this.reserveHandler}>
            {
              formElementsArray.map(formElement => (
                <Input
                  key={formElement.id}
                  label={formElement.config.label}
                  elementType={formElement.config.elementType}
                  elementConfig={formElement.config.elementConfig}
                  value={formElement.config.value}
                  invalid={!formElement.config.valid}
                  shouldValidate={formElement.config.validation}
                  touched={formElement.config.touched}
                  changed={(event) => this.inputChangedHandler(event, formElement.id)}
                  blur={(event) => this.blurHandler(event, formElement.id)}
                  currentLang={this.state.currentLang}
                />
              ))
            }
          </form>
          <div className={classes.ModalBtns}>
            <Button btnColor="WithoutBorder" clicked={this.closeReserveModal}>{t('reserveModal.cancelBtn')}</Button>
            <Button btnColor="Yellow" clicked={this.reserveHandler} disabled={!this.state.formIsValid}>{t('reserveModal.title')}</Button>
          </div>
          <div className={classes.ModalClose}><img src={close} alt={'close'} onClick={() => this.closeReserveModal()} /></div>
        </div>
      );
    };

    const popup = (
      <div className={classes.Popup}>
        <p className={classes.Title}>{t('reserveModal.popup.title')}</p>
        <p className={classes.Description}>{t('reserveModal.popup.description')}</p>
        <Button btnColor="Yellow" clicked={this.togglePopup}>{t('reserveModal.popup.continueBtn')}</Button>
      </div>
    )

    return (
      <Aux>
        {
          this.state.showSideDrawer ?
            <SideDrawer
              open={this.state.showSideDrawer}
              closed={this.sideDrawerClosedHandler}
            /> : null
        }
        <Header
          drawerToggleClicked={this.sideDrawerToggleHandler}
          onOpenModal={this.openReserveModal}
          onCloseModal={this.closeReserveModal}
          lang={this.state.lang}
          onChangeLang={this.changeLang}
          t={t}
        />
        <main className={classes.Content}>
          {this.props.children}
        </main>
        <Footer />
        {
          this.state.isShowModal ?
            <Modal show={this.state.isShowModal} closed={() => this.closeReserveModal()}>
              {
                this.state.width < 1025 ?
                  <Element
                    className={classes.Element}
                    style={{
                      position: 'relative',
                      height: '80vh',
                      overflow: 'scroll',
                    }}
                  >
                    <div className={classes.Wrapper}>
                      {form}
                    </div>
                  </Element> : form
              }
            </Modal> : null
        }
        {
          this.state.isShowPopup ?
            <Popup show={this.state.isShowPopup} closed={() => this.togglePopup()}>
              {popup}
            </Popup> : null
        }
      </Aux>
    );
  };
}

const mapStateToProps = state => {
  return {
    token: state.authReducer.token,
    locations: state.locationsReducer.locations,
    selectedLocation: state.locationsReducer.selectedLocation,
    selectedRoom: state.locationsReducer.selectedRoom,
    reserveFormSelectedLocation: state.locationsReducer.reserveFormSelectedLocation,
    currentLang: state.localizationReducer.currentLang
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onFetchGuestUser: () => dispatch(actions.fetchGuestUser()),
    onFetchLocations: (lang) => dispatch(actions.fetchLocations(lang)),
    onSelectLocation: (locationId) => dispatch(actions.selectLocation(locationId)),
    onReserveFormSelectedLocation: (locationId) => dispatch(actions.reserveFormSelectedLocation(locationId)),
    onBooking: (token, data) => dispatch(actions.booking(token, data)),
    onChangeLang: (lang, pathname, selectedLocation, selectedRoom) => dispatch(actions.changeLang(lang, pathname, selectedLocation, selectedRoom))
  };
};

const LayoutTranslation = withTranslation()(Layout);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LayoutTranslation));