import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Slider from 'react-slick';

import classes from './Locations.module.scss';
import Aux from '../../hoc/Aux/Aux';
import Title from '../UI/Title/Title';
import Location from './Location/Location';
import sliderArrowImg from '../../assets/images/mainPage/slider-arrow.svg';
import * as actions from '../../store/actions/index';
import './slider.scss';

function SamplePrevArrow(props) {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <img src={sliderArrowImg} alt={'prev'} style={{
        transform: 'rotate(180deg)'
      }} />
    </div>
  );
}

function SampleNextArrow(props) {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <img src={sliderArrowImg} alt={'next'} />
    </div>
  );
}

class Locations extends Component {
  state = {
    width: 0
  };

  componentDidMount() {
    const { currentLang, onFetchLocations } = this.props;
    onFetchLocations(currentLang);
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth });
  }

  selectedLocation = (id) => {
    if (!this.slider || this.slider.innerSlider.clickable) {
      this.props.history.push({ pathname: '/' + id });
      this.props.onSelectLocation(id);
      window.scrollTo(0, 0);
    }
  }

  renderLocations = () => {
    return this.props.locations.map(location => {
      return (
        <Location
          id={location.id}
          key={location.id}
          location={location}
          clicked={(id) => this.selectedLocation(id)}
          t={this.props.t}
        />
      );
    })
  }

  render() {
    const { t } = this.props;
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />
    };

    return (
      <Aux>
        <section className={classes.Locations}>
          <div className={classes.Container}>
            <Title>{t('mainPage.locationsSection.title')}</Title>
            {
              this.props.locations.length && this.props.locations.length > 3 ?
                <div className="LocationsSlider">
                  {
                    this.state.width >= 768 ?
                      <Slider {...settings} className={classes.List} ref={slider => this.slider = slider}>
                        {this.renderLocations()}
                      </Slider> : this.renderLocations()
                  }

                </div> : <div className={classes.Wrapper}>{this.renderLocations()}</div>
            }
          </div>
        </section>
      </Aux>
    );
  };
};

const mapStateToProps = state => {
  return {
    locations: state.locationsReducer.locations,
    location: state.locationsReducer.selectedLocation,
    currentLang: state.localizationReducer.currentLang,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onFetchLocations: (lang) => dispatch(actions.fetchLocations(lang)),
    onSelectLocation: (id) => dispatch(actions.selectLocation(id))
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Locations));