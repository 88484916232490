import React from 'react';

import classes from './Conditions.module.scss';
import FranchizeTitle from '../UI/FranchizeTitle/FranchizeTitle';
import FranchizeDescription from '../UI/FranchizeDescription/FranchizeDescription';
import FranchizeFigure from '../FranchizeFigure/FranchizeFigure';

const conditions = props => {
  const leftConditions = [
    'Выбор места / проверка соответствия имеющегося помещения',
    'Разработка плана помещения, оформление и наполнение',
    'Сметы на технику на выгодных условиях',
    'Сметы для закупки мебели, ремонта, вентиляции, материалов',
    'Базы знаний ведения бизнеса, учета, подбора кадров и контроля',
    'Подбор и подготовка персонала и методика их обучения'
  ];
  const rightConditions = [
    'Организация работы, меню, разработка плана мероприятий на месяц, открытие',
    'Доступ к автоматизированной системе учета',
    'Уникального авторского приложения с возможностью резерва комнат, удаленного заказа меню и встроенными функциями CRM',
    'План маркетинга и продвижения по Интернет-рекламе',
    'Передача прав на использование нематериальных активов',
  ];

  return (
    <section className={classes.Conditions}>
      <div className={classes.Container}>
        <FranchizeTitle><span>наши</span> условия</FranchizeTitle>
        <FranchizeDescription>Франчайзи получает полное сопровождение на всем пути развития бизнеса. Франшиза Plays включает:</FranchizeDescription>
        <div className={classes.Wrapper}>
          <ul className={classes.LeftConditions}>
            {leftConditions.map((item, idx) => <li key={idx} className={classes.Item}>{item}</li>)}
          </ul>
          <ul className={classes.RightConditions}>
            {rightConditions.map((item, idx) => <li key={idx} className={classes.Item}>{item}</li>)}
          </ul>
        </div>
        <FranchizeFigure onLeaveApplication={props.onLeaveApplication} />
      </div>
    </section>
  );
};

export default conditions;