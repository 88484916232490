import axios from '../../axios';
import * as actionTypes from './actionTypes';

export const booking = (token, data) => {
  return dispatch => {
		axios.post('/site/v1/booking', data, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		})
			.then(res => {
				const bookingPayload = res.data.payload;

				dispatch(bookingSuccess(bookingPayload));
			})
			.catch(err => {
				// dispatch(fetchGamesFail(err));
			});
	};
};

export const bookingSuccess = (bookingPayload) => {
  return {
    type: actionTypes.BOOKING_SUCCESS,
		bookingPayload
  }
}