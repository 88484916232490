import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
  news: [],
  pageSize: 6,
  totalNews: null
};

const fetchNews = (state, action) => {
  return updateObject(state, {pageSize: action.pageSize});
}

const fetchNewsSuccess = (state, action) => {
  return updateObject(state, {
    news: action.news,
    pageSize: action.news.length,
    totalNews: action.totalNews
  });
}

const showAllNews = (state, action) => {
  return fetchNews(state, action);
}

const showMore = (state, action) => {
  return fetchNews(state, action);
}

const reducer = (state = initialState, action) => {
  switch(action.type) {
    case actionTypes.FETCH_NEWS_SUCCESS: return fetchNewsSuccess(state, action);
    case actionTypes.SHOW_ALL_NEWS: return showAllNews(state, action);
    case actionTypes.SHOW_MORE: return showMore(state, action);
    default: return state;
  }
};

export default reducer;