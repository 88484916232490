import React from 'react';

import classes from './FranchizeButton.module.scss';
import Button from '../Button/Button';

const franchizeButton = props => (
  <span className={classes.FranchizeButton}>
    <Button btnColor="Yellow" clicked={props.onLeaveApplication}>Оставить заявку</Button>
  </span>
);

export default franchizeButton;