import React, {Component} from 'react';

import classes from './Address.module.scss';

class Address extends Component {
  selectRoom = (room) => {
    this.props.clicked(room);
  };

  render() {
    return (
      <ul className={classes.List}>
        {this.props.address ? this.props.address.rooms.map(room => {
          return (
            <li 
              style={{
                backgroundImage: room.image ? 'url(' + room.image + ')' : 'url(https://via.placeholder.com/248x248)',
              }}
              key={room.id} 
              className={classes.Item} 
              onClick={() => this.selectRoom(room)}
            >
              <div className={classes.Description}>
                <div className={classes.Top}>
                  <p className={classes.Title}>{room.description.title}</p>
                  <p className={classes.Price}>{room.price} грн</p>
                </div>
                <div className={classes.Bottom}>
                  <p className={classes.Category}>{room.category.description.title}</p>
                  <p className={classes.CountPlayers}>{room.min_players}-{room.max_players}</p>
                </div>
              </div>
            </li>
          );
        }) : null}
      </ul>
    );
  };
};

export default Address;