import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import classes from './StocksPage.module.scss';

import Aux from '../../hoc/Aux/Aux';
import Title from '../../components/UI/Title/Title';
import Stocks from '../../components/Stocks/Stocks';

import * as actions from '../../store/actions/index';

class StocksPage extends Component {
  componentDidMount() {
    const { currentLang, pageSize, onFetchStocks } = this.props;
    onFetchStocks(currentLang, pageSize);
  }

  showMore = () => {
    const { currentLang, stocks, onShowMore } = this.props;
    onShowMore(currentLang, stocks.length);
  }

  render() {
    const { stocks, totalStocks, t } = this.props;
    return (
      <Aux>
        <div className={classes.Wrapper}>
          <div className={classes.Container}>
            <Title>{t('stocksPage.title')}</Title>
            {
              stocks.length ?
                <Stocks
                  stocks={stocks}
                  totalStocks={totalStocks}
                  showMore={this.showMore}
                /> : null
            }
          </div>
        </div>
      </Aux>
    );
  };
}

const mapStateToProps = state => {
  return {
    stocks: state.stocksReducer.stocks,
    pageSize: state.stocksReducer.pageSize,
    totalStocks: state.stocksReducer.totalStocks,
    currentLang: state.localizationReducer.currentLang,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onFetchStocks: (lang, pageSize) => dispatch(actions.fetchStocks(lang, pageSize)),
    onShowMore: (lang, currentStocksLength) => dispatch(actions.showMore(lang, currentStocksLength)),
  }
};

const StocksPageTranslation = withTranslation()(StocksPage);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(StocksPageTranslation));