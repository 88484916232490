import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
  currentLang: 'uk'
};

const changeLangSuccess = (state, action) => {
  return updateObject(state, {currentLang: action.lang});
};

const reducer = (state = initialState, action) => {
  switch(action.type) {
    case actionTypes.CHANGE_LANG_SUCCESS: return changeLangSuccess(state, action);
    default: return state;
  };
};

export default reducer;