import React from 'react';

import classes from './SocialBtns.module.scss';
import SocialBtn from './SocialBtn/SocialBtn';

import telegram from '../../assets/images/mainPage/telegram.svg';
import instagram from '../../assets/images/mainPage/instagram.svg';
import youtube from '../../assets/images/mainPage/youtube.svg';

const socialBtns = (props) => {
  return(
    <ul className={classes.SocialBtns}>
      <SocialBtn navTo={'https://t.me/gamebarplays'} img={telegram}>Telegram</SocialBtn>
      <SocialBtn navTo={'https://www.instagram.com/gamebarplays/'} img={instagram}>Instagram</SocialBtn>
      <SocialBtn navTo={'https://www.youtube.com/channel/UCCYB70QbrckO0KJ4c93uy5g'} img={youtube}>Youtube</SocialBtn>
    </ul>
  );
}

export default socialBtns;