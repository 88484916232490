import React, {Component} from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import classes from './RoomPage.module.scss';

import Room from '../../components/Room/Room';
import Breadcrumbs from '../../components/UI/Breadcrumbs/Breadcrumbs';

import * as actions from '../../store/actions/index';

class RoomPage extends Component {
  componentDidMount() {
    this.props.onSelectedRoom(this.props.match.params.roomId);
  }

  goToMain = () => {
    this.props.history.push({pathname: '/'});
  }

  goBack = () => {
    this.props.history.goBack();
  }

  render() {
    const { t } = this.props;
    return(
      <section className={classes.Wrapper}>
        <div className={classes.Container}>
          {
            this.props.selectedRoom ?
              <div className={classes.Content}>
                <Breadcrumbs 
                  address={this.props.selectedRoom.description.address} 
                  room={this.props.selectedRoom.rooms.description.title} 
                  goBack={() => this.goBack()}
                  goToMain={() => this.goToMain()}
                />
                <Room room={this.props.selectedRoom.rooms} t={t} />
              </div> : null
          }
        </div>
      </section>
    );
  };
};

const mapStateToProps = state => {
  return {
    selectedRoom: state.locationsReducer.selectedRoom
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onSelectLocation: () => dispatch(actions.selectRoom()),
    onSelectedRoom: (roomId) => dispatch(actions.selectedRoom(roomId))
  };
};

const RoomPageTranslation = withTranslation()(RoomPage);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RoomPageTranslation));