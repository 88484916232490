import React, {Component} from 'react';

import classes from './Game.module.scss';
import Aux from '../../../hoc/Aux/Aux';

class Game extends Component {
  openModal = (id) => {
    this.props.onOpenModal(id);
  };

  render() {
    const {game} = this.props;
    return(
      <Aux>
        <li className={classes.Item} onClick={() => this.openModal(game.id)}>
          <p><img src={game.image ? game.image : 'https://via.placeholder.com/163x228'} alt={game.description.title} /></p>
          <p className={classes.Name}>{game.description.title}</p>
        </li>
      </Aux>
    );
  }
};

export default Game;
