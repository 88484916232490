export const FETCH_GUEST_USER_SUCCESS = 'FETCH_GUEST_USER_SUCCESS';

export const FETCH_GAMES = 'FETCH_GAMES';
export const FETCH_GAMES_SUCCESS = 'FETCH_GAMES_SUCCESS';
export const CHANGE_ACTIVE_CATEGORY = 'CHANGE_ACTIVE_CATEGORY';
export const FETCH_GAMES_FAILED = 'FETCH_GAMES_FAILED';

export const FETCH_STOCKS_SUCCESS = 'FETCH_STOCKS_SUCCESS';
export const SHOW_MORE = 'SHOW_MORE'

export const FETCH_SHOP = 'FETCH_SHOP';

export const FETCH_LOCATIONS = 'FETCH_LOCATIONS';
export const FETCH_LOCATIONS_SUCCESS = 'FETCH_LOCATIONS_SUCCESS';
export const SELECT_LOCATION = 'SELECT_LOCATION';
export const SELECT_LOCATION_SUCCESS = 'SELECT_LOCATION_SUCCESS';
export const SELECT_ROOM = 'SELECT_ROOM';
export const SELECT_ROOM_SUCCESS = 'SELECT_ROOM_SUCCESS';
export const RESERVE_FORM_SELECT_LOCATION = 'RESERVE_FORM_SELECT_LOCATION';
export const RESERVE_FORM_SELECT_LOCATION_SUCCESS = 'RESERVE_FORM_SELECT_LOCATION_SUCCESS';

export const BOOKING_SUCCESS = 'BOOKING_SUCCESS';

export const FETCH_NEWS_SUCCESS = 'FETCH_NEWS_SUCCESS';
export const SHOW_ALL_NEWS = 'SHOW_ALL_NEWS';

export const CHANGE_LANG_SUCCESS = 'CHANGE_LANG_SUCCESS';