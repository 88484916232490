import React from 'react';

import classes from './FranchizePage.module.scss';
import FranchizeMainContent from '../../components/FranchizeMainContent/FranchizeMainContent';
import FranchizeSecondContent from '../../components/FranchizeSecondContent/FranchizeSecondContent';
import Conditions from '../../components/Conditions/Conditions';
import Investments from '../../components/Investments/Investments';
import Advantages from '../../components/Advantages/Advantages';
import Team from '../../components/Team/Team';

const franchizePage = () => {
  const leaveApplicationHandler = () => {
    window.open('https://docs.google.com/forms/d/1jI6ljzr1Id1LbIVIP_QM13tMfh5fbjwt_WfsQ5YGos4/viewform?edit_requested=true', '_blank');
  };

  return (
    <div className={classes.FranchizePage}>
      <FranchizeMainContent onLeaveApplication={leaveApplicationHandler} />
      <FranchizeSecondContent />
      <Conditions onLeaveApplication={leaveApplicationHandler} />
      <Investments onLeaveApplication={leaveApplicationHandler} />
      <Advantages />
      <Team onLeaveApplication={leaveApplicationHandler} />
    </div>
  );
};

export default franchizePage;