import * as actionTypes from './actionTypes';
import axios from '../../axios';

export const fetchStocks = (locale, pageSize) => {
  return dispatch => {
		axios.get(`/site/v1/stocks?pageSize=${pageSize}`, {
      headers: {
        locale,
      },
    })
			.then(res => {
        const fetchedStocks = res.data.payload;
        const totalStocks = res.data.meta.total;

				dispatch(fetchStocksSuccess(fetchedStocks, totalStocks));
			})
			.catch(err => {
				// dispatch(fetchGamesFail(err));
			});
	};
};

export const fetchStocksSuccess = (stocks, totalStocks) => {
  return {
    type: actionTypes.FETCH_STOCKS_SUCCESS,
    stocks,
    totalStocks
  };
};

export const showMore = (locale, currentStocksLength) => {
  return dispatch => {
    dispatch(fetchStocks(locale, currentStocksLength + 6));
  };
}