import React, {Component} from 'react';
import { connect } from 'react-redux';

import classes from './Category.module.scss';
import * as actions from '../../../../store/actions/index';

class Category extends Component {
  changeActiveCategory = (category) => {
    this.props.onChangeActiveCategory(category);
    this.props.closed();
  }

  render() {
    let classNames = [classes.Item];
    if (this.props.category.isActive) {
      classNames = [classes.Item, classes.Active]
    } 
    if (this.props.first === this.props.category.name) {
      classNames = [classes.Item, classes.First];
    }
    if (this.props.first === this.props.category.name && this.props.category.isActive) {
      classNames = [classes.Item, classes.First, classes.Active];
    }

    return(
      <li className={classNames.join(' ')} onClick={() => this.changeActiveCategory(this.props.category)}>
        {this.props.category.name}
      </li>
    );
  }
};

const mapDispatchToProps = dispatch => {
  return {
    onChangeActiveCategory: (category) => dispatch(actions.changeActiveCategory(category))
  };
};

export default connect(null, mapDispatchToProps)(Category);