import React from 'react';

import NavigationItems from '../NavigationItems';
import classes from './SideDrawer.module.scss';
import Aux from '../../../../../hoc/Aux/Aux';
import closeBurger from '../../../../../assets/images/mainPage/burger-close.svg';
import SocialBtns from '../../../../SocialBtns/SocialBtns';
import Footer from '../../../../Footer/Footer';

const sideDrawer = (props) => {
  let attachedClasses = [classes.SideDrawer, classes.Close];

  if (props.open) {
    attachedClasses = [classes.SideDrawer, classes.Open];
  }

  return (
    <Aux>
      <div className={attachedClasses.join(' ')}>
        <div className={classes.CloseBurger}>
          <img src={closeBurger} alt={'close'} onClick={props.closed} />
        </div>
        <nav className={classes.Navigation}>
          <NavigationItems closed={props.closed} />
        </nav>
        <div className={classes.Social}>
          <SocialBtns />
        </div>
        <Footer />
      </div>
    </Aux>
  );
}

export default sideDrawer;