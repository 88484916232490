import React, {Component} from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import classes from './ShopPage.module.scss';

import Aux from '../../hoc/Aux/Aux';
import Title from '../../components/UI/Title/Title';
import Shop from '../../components/Shop/Shop';

import * as actions from '../../store/actions/index';

class ShopPage extends Component {
  componentDidMount() {
    this.props.onFetchShop(this.props.shopReducer);
  }

  render() {
    return(
      <Aux>
        <div className={classes.Wrapper}>
          <div className={classes.Container}>
            <Title>Магазин</Title>
            <Shop shop={this.props.shopReducer.shop} />
          </div>
        </div>
      </Aux>
    );
  };
}

const mapStateToProps = state => {
  return {
    shopReducer: {
      shop: state.shopReducer.shop
    }
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onFetchShop: (shop) => dispatch(actions.initShop(shop))
  }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ShopPage));