import React, { Component } from 'react';
import { Element } from 'react-scroll'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import classes from './MainPage.module.scss';

import Aux from '../../hoc/Aux/Aux';
import MainContent from '../../components/MainContent/MainContent';
import Locations from '../../components/Locations/Locations';
import NewsSection from '../../components/NewsSection/NewsSection';
import BonusSystem from '../../components/BonusSystem/BonusSystem';
import * as actions from '../../store/actions/index';

class MainPage extends Component {
  componentDidMount() {
    const { currentLang, onFetchNews } = this.props;
    onFetchNews(currentLang, 3);
  }

  redirectToNews = () => {
    const { history, currentLang, pageSize, onShowAllNews } = this.props;

    history.push({ pathname: '/news' });
    window.scrollTo(0, 0);
    onShowAllNews(currentLang, pageSize);
  }

  render() {
    const { news, t } = this.props;
    return (
      <Aux>
        <div className={classes.Container}>
          <div className={classes.Wrapper}>
            <MainContent t={t} />
          </div>
          <Element name="locations">
            <Locations t={t} />
          </Element>
          <NewsSection
            news={news}
            onRedirectToNews={() => this.redirectToNews()}
            t={t}
          />
          <BonusSystem t={t} />
        </div>
      </Aux>
    )
  }
};

const mapStateToProps = state => {
  return {
    news: state.newsReducer.news,
    pageSize: state.newsReducer.pageSize,
    totalNews: state.newsReducer.totalNews,
    currentLang: state.localizationReducer.currentLang,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onFetchNews: (lang, pageSize) => dispatch(actions.fetchNews(lang, pageSize)),
    onShowAllNews: (lang, pageSize) => dispatch(actions.showAllNews(lang, pageSize)),
    onShowMore: (lang, currentNewsLength) => dispatch(actions.showMoreNews(lang, currentNewsLength)),
  }
};

const MainPageTranslation = withTranslation()(MainPage);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MainPageTranslation));