import React from 'react';

import classes from './Advantages.module.scss';
import FranchizeTitle from '../UI/FranchizeTitle/FranchizeTitle';

const advantages = () => {
  const advantages = [
    {id: 1, title: 'Авторское приложение', description: 'Это наша фишка, приложение выполняет роль CRM системы, сервиса бронирования комнат, удаленного заказа по меню и оценки персонала по уровню клиентского сервиса. Приложение гарантирует высокий уровень возврата и лояльности клиентов.'},
    {id: 2, title: 'Оригинальный дизайн', description: 'Создание атмосферного PLAYS  - это основа успеха в среде молодежи, именно поэтому мы уделяем максимум внимания дизайну, наполнению играми, меню бара и кальяна, рекламе и маркетингу'},
    {id: 3, title: 'Прибыльная бизнес-система', description: 'Мы сравнили множество предложений на рынке готового бизнеса. Франшиза PLAYS – это выгодная  и прибыльная инвестиция. Максимальная автоматизация, система работы с персоналом и крутой маркетинг создают механизм по генерации высокой прибыли. '}
  ];

  return (
    <section className={classes.Advantages}>
      <div className={classes.Container}>
        <FranchizeTitle><span>ПРЕИМУЩЕСТВА</span> ФРАНШИЗЫ PLAYS</FranchizeTitle>
        <ul className={classes.AdvantagesList}>
          {
            advantages.map(advantage => {
              return (
                <li key={advantage.id} className={classes.Advantage}>
                  <p className={classes.Subtitle}>0{advantage.id}</p>
                  <p className={classes.Title}>{advantage.title}</p>
                  <p className={classes.Description}>{advantage.description}</p>
                </li>
              );
            })
          }
        </ul>
      </div>
    </section>
  );
};

export default advantages;