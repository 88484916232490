import React, {Component} from 'react';
import { DatePicker, ConfigProvider } from 'antd';
import enUS from 'antd/es/locale/en_US';
import ruRU from 'antd/es/locale/ru_RU';
import ukUA from 'antd/es/locale/uk_UA';
import moment from 'moment';
import 'moment/locale/ru';
import 'moment/locale/uk';

import './DatePicker.scss';

class DateTime extends Component {
  state = {
    mode: 'date',
    currentDate: null,
    locale: ruRU
  }

  componentDidMount() {
    this.setState({currentDate: moment()});
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { currentLang } = this.props;

    if (prevProps.currentLang !== prevState.locale.locale) {
      if (currentLang === 'en') {
        this.setState({locale: enUS});
      } else if (currentLang === 'uk') {
        this.setState({locale: ukUA});
      }
    }    
  }

  range = (start, end) => {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  }

  disabledDateTime = () => {
    const year = moment().year();
    const month = moment().month();
    const day = moment().date();
    const hours = moment().hours();
    const minutes = moment().minutes();

    const currentYear = this.state.currentDate.year();
    const currentMonth = this.state.currentDate.month();
    const currentDay = this.state.currentDate.date();
    const currentHours = this.state.currentDate.hours();
    const currentMinutes = this.state.currentDate.minutes();

    if (year === currentYear && month === currentMonth && day === currentDay && hours === currentHours) {
      return {
        disabledHours: () => this.range(0, hours),
        disabledMinutes: () => this.range(0, minutes),
      };
    } else if (year === currentYear && month === currentMonth && day === currentDay && hours !== currentHours) {
      return {
        disabledHours: () => this.range(0, hours),
        disabledMinutes: () => this.range(currentMinutes, minutes),
      };
    }
  }

  render() {
    return (
      <ConfigProvider locale={this.state.locale}>
        <DatePicker 
          mode={this.state.mode}
          format="DD.MM.YY HH:mm"
          autoFocus={true}
          placeholder={this.props.placeholder}
          onChange={(date, dateString) => {
            this.setState({currentDate: date});
            this.props.onChange(date)
          }}
          onOk={(value) => this.props.onChange(value)} 
          disabledDate={(current) => {
            return moment().add(-1, 'days') >= current;
          }}
          onPanelChange={(value, mode) => {
            if (mode === 'time' || mode === 'date') {
              this.setState({mode});
            }
          }}
          disabledTime={this.disabledDateTime}
          showTime={{
            format: 'HH:mm',
            minuteStep: 5
          }}
        />
      </ConfigProvider>
    );    
  }
}

export default DateTime;