import React from 'react';

import classes from './Download.module.scss';
import Title from '../UI/AboutTitle/AboutTitle';
import Description from '../UI/AboutDescription/AboutDescription';
import Links from './Links/Links';

const download = (props) => (
  <div className={classes.Download}>
    <Title>{props.t('aboutAppPage.downloadSection.title')} <span>{props.t('aboutAppPage.downloadSection.markedTitle')}</span></Title>
    <Description>{props.t('aboutAppPage.downloadSection.description')}</Description>
    <Links />
  </div>
);

export default download;