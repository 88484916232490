import * as actionTypes from './actionTypes';
import axios from '../../axios';

export const fetchLocations = (locale) => {
	return dispatch => {
		axios.get('/site/v1/establishments', {
			headers: {
				locale,
			},
		})
			.then(res => {
				const fetchedLocations = res.data.payload;

				dispatch(fetchLocationsSuccess(fetchedLocations));
			})
			.catch(err => {
				// dispatch(fetchLocationsFail(err));
			});
	};
};

export const fetchLocationsSuccess = (locations) => {
	return {
		type: actionTypes.FETCH_LOCATIONS_SUCCESS,
		locations
	};
}


export const selectLocation = (id) => {
	return dispatch => {
		axios.get(`/site/v1/establishments/${id}/rooms`)
			.then(res => {
				const fetchedLocation = res.data.payload;

				dispatch(selectLocationSuccess(fetchedLocation));
			})
			.catch(err => {
				// dispatch(fetchLocationsFail(err));
			});
	};
};

export const selectLocationSuccess = (location) => {
	return {
		type: actionTypes.SELECT_LOCATION_SUCCESS,
		location
	};
}

export const selectedLocation = (id) => {
	return selectLocation(id);
};

export const reserveFormSelectedLocation = (id) => {
	return dispatch => {
		axios.get(`/site/v1/establishments/${id}/rooms`)
			.then(res => {
				const fetchedLocation = res.data.payload;

				dispatch(reserveFormSelectLocationSuccess(fetchedLocation));
			})
			.catch(err => {
				// dispatch(fetchLocationsFail(err));
			});
	};
}

export const reserveFormSelectLocationSuccess = (location) => {
	return {
		type: actionTypes.RESERVE_FORM_SELECT_LOCATION_SUCCESS,
		location
	};
}


export const selectRoom = (roomId) => {
	return dispatch => {
		axios.get(`/site/v1/rooms/${roomId}`)
			.then(res => {
				const fetchedRoom = res.data.payload;

				dispatch(fetchRoomSuccess(fetchedRoom));
			})
			.catch(err => {
				// dispatch(fetchLocationsFail(err));
			});
	};
};

export const fetchRoomSuccess = (room) => {
	return {
		type: actionTypes.SELECT_ROOM_SUCCESS,
		room
	}
}

export const selectedRoom = (roomId) => {
	return selectRoom(roomId);
};