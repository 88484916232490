import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
  stocks: [],
  pageSize: 6,
  totalStocks: null
};

const fetchStocks = (state, action) => {
  return updateObject(state, {pageSize: action.pageSize});
};

const fetchStocksSuccess = (state, action) => {
  return updateObject(state, {
    stocks: action.stocks,
    pageSize: action.stocks.length,
    totalStocks: action.totalStocks
  });
}

const showMore = (state, action) => {
  return fetchStocks(state, action);
}

const reducer = (state = initialState, action) => {
  switch(action.type) {
    case actionTypes.FETCH_STOCKS_SUCCESS: return fetchStocksSuccess(state, action);
    case actionTypes.SHOW_MORE: return showMore(state, action);
    default: return state;
  }
};

export default reducer;