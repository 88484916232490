import React from 'react';

import classes from './FranchizeSecondContent.module.scss';
import FranchizeTitle from '../UI/FranchizeTitle/FranchizeTitle';
import FranchizeDescription from '../UI/FranchizeDescription/FranchizeDescription';
import photoImg from '../../assets/images/franchizePage/photo.png';

const franchizeSecondContent = props => {
  const features = [
    { id: 1, title: 'Оптимальный формат PLAYS – 7-10 комнат площадью 8 – 20 кв.м + бар.' },
    { id: 2, title: 'Расположение в спальных районах, в популярных у молодежи местах.' },
    { id: 3, title: 'Оригинальное оформление и оснащение  лучшей техникой и популярными видео-играми.' },
  ];

  return (
    <section className={classes.FranchizeSecondContent}>
      <div className={classes.Container}>
        <div className={classes.Top}>
          <div className={classes.Left}><img src={photoImg} alt="people" /></div>
          <div className={classes.Right}>
            <FranchizeTitle><span>PLAYS</span> – это игровые бары-кальянные</FranchizeTitle>
            <div className={classes.Descriptions}>
              <FranchizeDescription>Основным преимуществом PLAYS является выбор ниши на стыке самых трендовых бизнесов «lounge бар-кальянная» и «Е-sport game центр».</FranchizeDescription>
              <FranchizeDescription>Отдельные комнаты оснащены лучшей игровой техникой, широкоформатными видео-проигрывателями (TV или проектор) и наполнены атмосферой отдыха и развлечений.</FranchizeDescription>
            </div>
          </div>
        </div>
        <ul className={classes.Features}>
          {features.map(feature => <li key={feature.id} className={classes.Feature}>{feature.title}</li>)}
        </ul>
      </div>
    </section>
  );
};

export default franchizeSecondContent;