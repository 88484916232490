import React from 'react';
import { withTranslation } from 'react-i18next';

import classes from './NavigationItems.module.scss';
import NavigationItem from './NavigationItem/NavigationItem';

const navigationItems = (props) => (
  <ul className={classes.NavigationItems}>
    <NavigationItem closed={props.closed} className="main" link="/" exact>{props.t('header.nav.main')}</NavigationItem>
    <NavigationItem closed={props.closed} className="menu" link="/menu">{props.t('header.nav.menu')}</NavigationItem>
    <NavigationItem closed={props.closed} className="games" link="/games">{props.t('header.nav.games')}</NavigationItem>
    <NavigationItem closed={props.closed} className="stocks" link="/stocks">{props.t('header.nav.stocks')}</NavigationItem>
    {/* <NavigationItem closed={props.closed} link="/shop">Магазин</NavigationItem> */}
    <NavigationItem closed={props.closed} className="about" link="/about">{props.t('header.nav.app')}</NavigationItem>
    <NavigationItem closed={props.closed} className="franchise" link="/franchise">{props.t('header.nav.franchise')}</NavigationItem>
  </ul>
);

const NavigationItemsTranslation = withTranslation()(navigationItems);

export default NavigationItemsTranslation;