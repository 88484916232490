import React from 'react';

import classes from './Footer.module.scss';

import Logo from '../../components/Logo/Logo';
import SocialBtns from '../SocialBtns/SocialBtns';

const footer = (props) => {
  return(
    <footer>
      <div className={classes.Container}>
        <Logo />
        <p className={classes.Copyright}>PLAYS © 2021. All rights reserved</p>
        <SocialBtns />
      </div>
    </footer>
  );
};

export default footer;