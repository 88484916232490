import React from 'react';

import classes from './Team.module.scss';
import FranchizeTitle from '../UI/FranchizeTitle/FranchizeTitle';
import FranchizeButton from '../UI/FranchizeButton/FranchizeButton';
import teamImg from '../../assets/images/franchizePage/team.png';

const team = props => {
  return (
    <section className={classes.Team}>
      <div className={classes.Container}>
        <div className={classes.Left}>
          <FranchizeTitle><span>Команда</span> Plays</FranchizeTitle>
          <div className={classes.Wrapper}>
            <p className={classes.Description}>Мы уже много лет в этом бизнесе, знаем все подводные камни и как их избежать, и мы знаем, как достичь в игровом и кальянном бизнесе высокой прибыли. Главное - Ваше желание и готовность работать и учиться. Приглашаем партнеров для открытия  игровых баров-кальянных PLAYS в Вашем городе.</p>
            <div className={classes.InnerWrapper}>
              <div className={classes.Founder}>
                <p className={classes.Name}>Артур</p>
                <p className={classes.Role}>Основатель сети Plays</p>
              </div>
              <div><FranchizeButton onLeaveApplication={props.onLeaveApplication} /></div>
            </div>
          </div>
        </div>
        <div className={classes.Right}><img src={teamImg} alt="team" /></div>
      </div>
    </section>
  );
};

export default team;