import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

import tShirtImg from '../../assets/images/shopPage/t-shirt.png';
import stickerImg from '../../assets/images/shopPage/sticker.png';
import mouthpieceImg from '../../assets/images/shopPage/mouthpiece.png';

const initialState = {
  shop: [
    {price: '550 грн', img: tShirtImg, title: 'Футболка Plays Boy', description: 'Удлиненная футболка с принтом, дизайн которого мы разработали сами. Футболки удлиненные, хорошего качества, пошиты в Украине.'},
    {price: 'Цена — 10 грн\\шт. | 70 грн\\пак (8 шт.)', img: stickerImg, title: 'Наклейки', description: 'Наклейки с нашими персонажами Plays Girl и Plays Boy. Наклейки проламинированы, что позволит им долго не стираться даже будучи наклеными на поверхность с которой часто контактируют. Например телефон.'},
    {price: '150 грн', img: mouthpieceImg, title: 'Персональный мундштук', description: 'Незаменимый гаджет в арсенале настоящего любителя кальянов. Наши персональные мундштуки всегда выходят в ограниченном тираже. Сейчас в продаже вторая серия в которой доступно три разных дизайна.'},
    {price: '550 грн', img: tShirtImg, title: 'Футболка Plays Boy', description: 'Удлиненная футболка с принтом, дизайн которого мы разработали сами. Футболки удлиненные, хорошего качества, пошиты в Украине.'},
    {price: 'Цена — 10 грн\\шт. | 70 грн\\пак (8 шт.)', img: stickerImg, title: 'Наклейки', description: 'Наклейки с нашими персонажами Plays Girl и Plays Boy. Наклейки проламинированы, что позволит им долго не стираться даже будучи наклеными на поверхность с которой часто контактируют. Например телефон.'},
    {price: '150 грн', img: mouthpieceImg, title: 'Персональный мундштук', description: 'Незаменимый гаджет в арсенале настоящего любителя кальянов. Наши персональные мундштуки всегда выходят в ограниченном тираже. Сейчас в продаже вторая серия в которой доступно три разных дизайна.'}
  ]
};

const fetchShop = (state, action) => {
  return updateObject(state);
}

const reducer = (state = initialState, action) => {
  switch(action.type) {
    case actionTypes.FETCH_SHOP: return fetchShop(state, action);
    default: return state;
  }
};

export default reducer;