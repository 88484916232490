import React, {Component} from 'react';
import { withRouter } from 'react-router-dom';

import classes from './BonusSystem.module.scss';
import blackMatte from '../../assets/images/mainPage/black-matte.png';

import Aux from '../../hoc/Aux/Aux';
import Button from '../UI/Button/Button';

class BonusSystem extends Component {
  redirect = () => {
    this.props.history.push({pathname: '/about'});
    window.scrollTo(0, 0)
  };

  render() {
    const { t } = this.props;
    return(
      <Aux>
        <section className={classes.BonusSystem}>
          <div className={classes.Container}>
            <div className={classes.Left}>
              <h3 className={classes.Title}>{t('mainPage.bonusSystemSection.title')}</h3>
              <p className={classes.Description}>{t('mainPage.bonusSystemSection.description')}</p>
              <Button clicked={this.redirect} btnColor="Yellow">{t('mainPage.bonusSystemSection.detailBtn')}</Button>
            </div>
            <div className={classes.Right}>
              <img src={blackMatte} alt={'blackMatte'} />
            </div>
          </div>
        </section>
      </Aux>
    );
  };
};

export default withRouter(BonusSystem);