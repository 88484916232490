import React, {Component} from 'react';
import Slider from "react-slick";

import classes from './Room.module.scss';
import prevBtn from '../../assets/images/roomPage/prev.svg';
import nextBtn from '../../assets/images/roomPage/next.svg';

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, left: '10px', width: '15px', height: '20px' }}
      onClick={onClick}
    >
      <img src={prevBtn} alt={'prev'} />
    </div>
  );
}

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, right: '10px', width: '15px', height: '20px' }}
      onClick={onClick}
    >
      <img src={nextBtn} alt={'next'} />
    </div>
  );
}

class Room extends Component {
  state = {
    nav1: null,
    nav2: null
  };

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2
    });
  }

  renderItems = (room) => {
    const gallery = [
      'https://via.placeholder.com/248x248/0000FF', 
      'https://via.placeholder.com/248x248/FF00FF', 
      'https://via.placeholder.com/248x248/FFFFFF', 
      'https://via.placeholder.com/248x248/FF0000',
      'https://via.placeholder.com/248x248/F0F0F0',
    ];
    if (room.gallery.length) {
      return this.props.room.gallery.map((img, idx) => {
        return (
          <div key={idx} className={classes.Item}>
            <img src={img} alt={''} />
          </div>
        );
      });
    } else {
      return gallery.map((img, idx) => {
        return (
          <div key={idx} className={classes.Item}>
            <img src={img} alt={''} />
          </div>
        );
      });
    }
  };

  render() {
    const {room, t} = this.props;
    return (
      <div className={classes.Wrapper}>
        <div className={classes.Left}>
          <Slider
            className={classes.Top}
            asNavFor={this.state.nav2}
            ref={slider => (this.slider1 = slider)}
            arrows={false}
            responsive={[
              {
                breakpoint: 1024,
                settings: {
                  arrows: true,
                  nextArrow: <SampleNextArrow />,
                  prevArrow: <SamplePrevArrow />
                }
              }
            ]}
          >
            {this.renderItems(room)}
          </Slider>
          <Slider
            className={classes.Bottom}
            asNavFor={this.state.nav1}
            ref={slider => (this.slider2 = slider)}
            slidesToShow={3}
            swipeToSlide={true}
            focusOnSelect={true}
            arrows={false}
          >
            {this.renderItems(room)}
          </Slider> 
        </div>  
        <div className={classes.Right}>
          <p className={classes.Title}>{room.description.title}</p>
          <p className={classes.Category}>{t('roomPage.category')} <span>{room.category.description.title}</span></p>
          <p className={classes.Price}>{t('roomPage.price')} <span>{room.price}</span></p>
          <p className={classes.MaxPlayers}>{t('roomPage.maxPlayers')} <span>{room.max_players}</span></p>
          <p className={classes.Projector}>{t('roomPage.projector')} <span>{room.screen}</span></p>
          <p className={classes.Description}>{room.description.description}</p>
        </div>
      </div>
    );
  }
};

export default Room;