import React from 'react';

import classes from './FranchizeMainContent.module.scss';
import FranchizeButton from '../UI/FranchizeButton/FranchizeButton';
import FranchizeDescription from '../UI/FranchizeDescription/FranchizeDescription';
import heroesImg from '../../assets/images/franchizePage/heroes.png';

const franchizeMainContent = props => {
  return (
    <section className={classes.FranchizeMainContent}>
      <div className={classes.Container}>
        <div className={classes.Left}>
          <p className={classes.Title}>создай свой <span>Plays</span></p>
          <div className={classes.Description}>
            <FranchizeDescription>Самые трендовые бизнесы в сфере развлечений в одной франшизе.</FranchizeDescription>
          </div>
          <FranchizeButton onLeaveApplication={props.onLeaveApplication} />
        </div>
        <div className={classes.Right}><img src={heroesImg} alt="heroes" /></div>
      </div>
    </section>
  );
};

export default franchizeMainContent;