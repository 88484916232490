import React from 'react';

import classes from './Links.module.scss';
import appStoreImg from '../../../assets/images/aboutAppPage/app-store.png';
import googlePlayImg from '../../../assets/images/aboutAppPage/google-play.png';

const links = (props) => (
  <div className={classes.Links}>
    <a className={classes.AppStore} target="_blank" href="https://apps.apple.com/il/app/game-bar-plays/id1490254014" rel="noopener noreferrer">
      <img src={appStoreImg} alt={'App Store'} />
    </a>
    <a className={classes.GooglePlay} target="_blank" href="https://play.google.com/store/apps/details?id=com.gameplaysbar" rel="noopener noreferrer">
      <img src={googlePlayImg} alt={'Google Play'} />
    </a>
  </div>
);

export default links;