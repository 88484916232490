import React, {useState} from 'react';
import DatePicker, {registerLocale} from "react-datepicker";
import { setHours, setMinutes, getTime } from 'date-fns/esm';
import moment from 'moment';
import ru from 'date-fns/locale/ru';

import './TimePicker.scss';

registerLocale('ru', ru);


function TimePicker (props) {
  const [startDate, setStartDate] = useState(
    setHours(setMinutes(new Date(), new Date().getMinutes()), new Date().getHours())
  );

  setTimeout(() => {
    const inputs = document.querySelectorAll(".time-picker input");

    inputs.forEach(inp => {
      inp.disabled = true;
    });
  }, 0);

  const handleOnChange = (date) => {
    if (date) {
      const hours = date.getHours();
      const minutes = date.getMinutes();
      const fullInfo = `${hours}:${minutes}`;
      props.onChange(fullInfo);
      setStartDate(date);
    }
  }
  return (
    <div className="time-picker">
      <DatePicker
        className={props.className}
        placeholderText={props.placeholder}
        selected={props.value ? startDate : ''}
        onChange={date => setStartDate(date)}
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={10}
        timeCaption="Time"
        dateFormat="HH:mm"
        timeFormat="HH:mm"
        onChange={date => handleOnChange(date)}
        onBlur={element => props.onBlur(element)}
        minTime={getTime(startDate)}
        maxTime={moment().endOf('day').toDate()}
        popperModifiers={{
          offset: {
            enabled: true,
            offset: "0px, -265px"
          },
        }}
      />
    </div>
  );
};

export default TimePicker;