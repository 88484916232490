import React from 'react';

import classes from './DrawerToggle.module.scss';
import burger from '../../../../../../assets/images/mainPage/burger-open.svg';

const drawerToggle = (props) => (
  <div className={classes.DrawerToggle} onClick={props.clicked}>
    <img src={burger} alt={'burger'} />
  </div>
);

export default drawerToggle;