import React, {Component} from 'react';
import { withRouter } from 'react-router-dom';
import { Select } from 'antd';

import classes from './Header.module.scss';
import './Header.scss';
import Logo from '../Logo/Logo';
import NavigationItems from './Navigation/NavigationItems/NavigationItems';
import Button from '../UI/Button/Button';
import DrawerToggle from './Navigation/NavigationItems/SideDrawer/DrawerToggle/DrawerToggle';

const { Option } = Select;

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = { width: 0 };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }
  
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }
  
  updateWindowDimensions() {
    this.setState({ width: window.innerWidth });
  }

  openReserveModal = () => {
    this.props.onOpenModal();
  };

  closeReserveModal = () => {
    this.props.onCloseModal();
  };

  onChangeSelect = (e) => {
    this.props.onChangeLang(e);
  }

  render() {
    const {lang} = this.props;
    const { t } = this.props;

    return (
      <header className={classes.MainHeader}>
        <Logo />
        <nav className={classes.DesktopOnly}>
          <NavigationItems t={t} />
        </nav>
        <div className={classes.Wrapper}>
          <Select
            showSearch
            defaultValue={lang.elementConfig.options[2].displayValue}
            onChange={this.onChangeSelect}
          >
            {
              lang.elementConfig.options.map(opt => {
                return(
                  <Option 
                    key={opt.value}
                    className={classes.Item} 
                    value={opt.value}
                  >
                    <div className="Value">{opt.displayValue}</div>
                  </Option>
                );
              })
            }
          </Select>
          {
            this.props.location.pathname === '/' || this.state.width >= 1025 ?
              <Button
                className={classes.DesktopOnly}
                btnColor="Yellow"
                clicked={(e) => this.openReserveModal(e)}
              >{t('header.reserveBtn')}</Button> : null
          }
        </div>
        <DrawerToggle clicked={this.props.drawerToggleClicked} />
      </header>
    );
  }
}

export default withRouter(Header);
