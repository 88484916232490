import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
  locations: [],
  selectedLocation: null,
  selectedRoom: null,
  reserveFormSelectedLocation: null
}

const fetchLocations = (state, action) => {
  return updateObject(state);
};

const fetchLocationsSuccess = (state, action) => {
  return updateObject(state, {locations: action.locations});
}

const selectLocation = (state, action) => {
  return updateObject(state);
}

const selectLocationSuccess = (state, action) => {
  return updateObject(state, {selectedLocation: action.location});
}

const reserveFormSelectLocation = (state, action) => {
  return updateObject(state);
}

const reserveFormSelectLocationSuccess = (state, action) => {
  return updateObject(state, {reserveFormSelectedLocation: action.location});
}

const selectRoom = (state, action) => {
  return updateObject(state);
}

const selectRoomSuccess = (state, action) => {
  return updateObject(state, {selectedRoom: action.room});
}

const reducer = (state = initialState, action) => {
  switch(action.type) {
    case actionTypes.FETCH_LOCATIONS: return fetchLocations(state, action);
    case actionTypes.FETCH_LOCATIONS_SUCCESS: return fetchLocationsSuccess(state, action);
    case actionTypes.SELECT_LOCATION: return selectLocation(state, action);
    case actionTypes.SELECT_LOCATION_SUCCESS: return selectLocationSuccess(state, action);
    case actionTypes.RESERVE_FORM_SELECT_LOCATION: return reserveFormSelectLocation(state, action);
    case actionTypes.RESERVE_FORM_SELECT_LOCATION_SUCCESS: return reserveFormSelectLocationSuccess(state, action);
    case actionTypes.SELECT_ROOM: return selectRoom(state, action);
    case actionTypes.SELECT_ROOM_SUCCESS: return selectRoomSuccess(state, action);
    default: return state;
  }
};

export default reducer;