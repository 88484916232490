import React, {Component} from 'react';
import { Select } from 'antd';

import classes from './Select.scss';

const { Option } = Select;

class Selecter extends Component {
  onChange = (value) => {
    this.props.onChange(value);
  }

  render() {
    return(
      <Select
        // showSearch
        placeholder={this.props.placeholder}
        optionFilterProp="children"
        onChange={this.onChange}
        onBlur={this.onBlur}
        // filterOption={(input, option) =>
        //   option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        // }
        disabled={this.props.disabled}
      >
        {
          this.props.options.map(opt => {
            return(
              <Option 
                key={opt.value}
                className={classes.Item} 
                value={opt.id}
              >
                {
                  opt.countPlayers ?
                    <div className="Wrapper">
                      <div className="Value">{opt.displayValue}</div>
                      <div className="CountPlayers">{opt.countPlayers}</div>
                    </div> : opt.displayValue
                }
              </Option>
            );
          })
        }
      </Select>
    );
  }
};

export default Selecter;