import * as actionTypes from './actionTypes';
import axios from '../../axios';

export const fetchNews = (locale, pageSize) => {
  return dispatch => {
    axios.get(`/site/v1/events/feed?pageSize=${pageSize}`, {
      headers: {
        locale,
      },
    })
      .then(res => {
        const fetchedNews = res.data.payload;
        const totalNews = res.data.meta.total;

        dispatch(fetchNewsSuccess(fetchedNews, totalNews));
      })
      .catch(err => {
        // dispatch(fetchNewsFail(err));
      });
  };
};

export const fetchNewsSuccess = (news, totalNews) => {
  return {
    type: actionTypes.FETCH_NEWS_SUCCESS,
    news,
    totalNews
  };
};

export const showAllNews = (locale, currentNewsLength) => {
  return dispatch => {
    dispatch(fetchNews(locale, currentNewsLength + 3));
  }
}

export const showMoreNews = (locale, currentNewsLength) => {
  return dispatch => {
    dispatch(fetchNews(locale, currentNewsLength + 6));
  };
}