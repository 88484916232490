import axios from '../../axios';
import * as actionTypes from './actionTypes';

const fetchGuestUserSuccess = (token) => {
  return {
    type: actionTypes.FETCH_GUEST_USER_SUCCESS,
    token,
  };
};

export const fetchGuestUser = () => {
  return dispatch => {
    axios.post('/site/v1/user/guest')
      .then(res => {
        const { token } = res.data.payload;

        dispatch(fetchGuestUserSuccess(token));
      })
      .catch(err => {
        console.log(err)
      });
  };
};