import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
  bookingPayload: null
};

const bookingSuccess = (state, action) => {
  return updateObject(state, {bookingPayload: action.bookingPayload});
};

const reducer = (state = initialState, action) => {
	switch(action.type) {
    case actionTypes.BOOKING_SUCCESS: return bookingSuccess(state, action);
    default: return state;
	}
};

export default reducer;