import React, {Component} from 'react';
import { Link } from 'react-scroll'

import classes from './MainContent.module.scss';
import heroesImg from '../../assets/images/mainPage/devices.png';

import Aux from '../../hoc/Aux/Aux';
import Features from '../Features/Features';
import Button from '../UI/Button/Button';
import SocialBtns from '../SocialBtns/SocialBtns';

class MainContent extends Component {
  constructor(props) {
    super(props);
    this.state = { width: 0 };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }
  
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }
  
  updateWindowDimensions() {
    this.setState({ width: window.innerWidth });
  }

  scrollToSection = (section) => {
    this.props.onScrollTo(section);
  }

  render() {
    const { t } = this.props;

    return(
      <Aux>
        <section className={classes.Main}>
          <div className={classes.MainContent}>
            <div className={classes.MainContentTop}>
              <img className={classes.Heroes} src={heroesImg} alt={'heroes'} />
              <p className={classes.Plays}><span>{t('mainPage.plays.name')}</span></p>
              <Features t={t} />
            </div>
            <div className={classes.MainContentBottom}>
              <div className={classes.Wrapper}>
                <p className={classes.Title}><span>{t('mainPage.mainContent.markedTitle')}</span> {t('mainPage.mainContent.title')}</p>
                <p className={classes.Description}>{t('mainPage.mainContent.description')}</p>
                <Link to="locations" spy={true} smooth={true} duration={1000} className={classes.ChooseRoom}>
                  <Button
                    btnColor={'Transparent'}
                  >{t('mainPage.mainContent.chooseRoomBtn')}</Button>
                </Link>
              </div>
              <SocialBtns />
            </div>
          </div>
        </section>
      </Aux>
    );
  };
}

export default MainContent;